import { CoCThrowResult } from '../types';
import ThrowResultsList from './ThrowResultsList';
import {
    Card,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    Badge
} from 'reactstrap';

function CthulhuDiceResult(throwResult: CoCThrowResult, id: string) {
    return (
        <Card body key={id}>
            <CardTitle tag="h4">
                <Badge color="primary">{throwResult.baseThrow}</Badge>
            </CardTitle>
            <CardSubtitle>
                <Badge color="success">+2: {throwResult.twoDieBonus}</Badge>
                <Badge color="info">+1: {throwResult.oneDieBonus}</Badge>
                <Badge color="secondary">-1: {throwResult.oneDiePenalty}</Badge>
                <Badge color="warning">-2: {throwResult.twoDiePenalty}</Badge>
            </CardSubtitle>
            <CardText className="opacity-2">
                {ThrowResultsList(throwResult.allPartialThrows)}
            </CardText>
        </Card>
    );
}

export default CthulhuDiceResult;