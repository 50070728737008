import { MultipleThrowResult } from '../types';
import ThrowResultsList from './ThrowResultsList';
import {
    Card,
    CardText,
    Badge
} from 'reactstrap';

function RangeDiceResult(throwResult: MultipleThrowResult, id: string) {
    const resultSum = throwResult.results.reduce((sum, value) => sum + value);
    return (
        <Card body key={id}>
            <CardText>
                <Badge color="primary">{throwResult.dieType}:</Badge>
                <Badge color="success">{resultSum}</Badge>
                <div className="opacity-2">{ThrowResultsList(throwResult.results)}</div>
            </CardText>
        </Card>
    );
}

export default RangeDiceResult;