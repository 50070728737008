import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { UnifiedDiceTrowResult } from './types';
import * as Convert from './convert';
import * as Queries from './queries';
import SimpleDiceResult from './results/SimpleDiceResult';
import RangeDiceResult from './results/RangeDiceResult';
import CthulhuDiceResult from './results/CthulhuDiceResult';

import { Spinner } from 'reactstrap';
import MultipleDiceResult from './results/MultipleDiceResult';

const MAX_RESULTS = 10;

function GetThrowResult(unifiedResult: UnifiedDiceTrowResult){
    switch (unifiedResult.throwType){
        case 'CoCThrowResult':
            const cthulhuResult = Convert.ToCoCThrowResult(unifiedResult);
            return CthulhuDiceResult(cthulhuResult, unifiedResult.id);
        case 'SimpleThrowResult':
            const simpleResult = Convert.ToSimpleThrowResult(unifiedResult);
            return SimpleDiceResult(simpleResult, unifiedResult.id);
        case 'RangeThrowResult':
            const rangeResult = Convert.ToRangeThrowResult(unifiedResult);
            return RangeDiceResult(rangeResult, unifiedResult.id);
        case 'MultipleThrowResult':
            const multiResult = Convert.ToMultipleThrowResult(unifiedResult);
            return MultipleDiceResult(multiResult, unifiedResult.id);
        default:
            throw RangeError('unknown dice throw type');
    }
}

function ThrowResults() {
    const { loading, error, data, subscribeToMore } = useQuery(Queries.GET_THROWS_QUERY, {
        variables: { top: MAX_RESULTS },
    });

    useEffect(() => {
        return subscribeToMore({
            document: Queries.ON_DICE_THROWN_SUBSCRIPTION,
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newThrowResult = subscriptionData.data.onDiceThrown as UnifiedDiceTrowResult;
                return {throws: [newThrowResult, ...prev.throws]}
            }
        });
    });

    if (loading) return (<div><Spinner color="dark" /></div>);
    if (error) return (<div>{`Results Error! ${error}`}</div>);
    
    return (
        <div>
            <h4>Results</h4>
            {data && data.throws && data.throws.map((result: UnifiedDiceTrowResult) => GetThrowResult(result))}
        </div>
    );
}

export default ThrowResults;