import { RangeThrowResult } from '../types';
import ThrowResultsList from './ThrowResultsList';
import {
    Card,
    CardText,
    Badge
} from 'reactstrap';

function RangeDiceResult(throwResult: RangeThrowResult, id: string) {
    return (
        <Card body key={id}>
            <CardText>
                <Badge color="primary">{throwResult.count}{throwResult.dieType}:</Badge>
                {ThrowResultsList(throwResult.results)}
            </CardText>
        </Card>
    );
}

export default RangeDiceResult;