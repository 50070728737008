import {
    CoCThrowResult,
    RangeThrowResult,
    SimpleThrowResult,
    DiceThrowResultTuple,
    SingleDieThrowResult,
    UnifiedDiceTrowResult,
    MultipleThrowResult
} from './types';

function getResultsDictionary(unifiedResult: UnifiedDiceTrowResult) {
    return unifiedResult.results.reduce(
        (dict, current) => ({...dict, [current.name]: current.values}),
        {} as { [key: string]: SingleDieThrowResult[] });
}

export function ToCoCThrowResult(unifiedResult: UnifiedDiceTrowResult): CoCThrowResult {
    const results = getResultsDictionary(unifiedResult);
    return {
        baseThrow: results["baseThrow"][0].value,
        oneDieBonus: results["oneDieBonus"][0].value,
        twoDieBonus: results["twoDieBonus"][0].value,
        oneDiePenalty: results["oneDiePenalty"][0].value,
        twoDiePenalty: results["twoDiePenalty"][0].value,
        allPartialThrows: results["allPartialThrows"].map(r => r.value)
    };
}

export function ToSimpleThrowResult(unifiedResult: UnifiedDiceTrowResult): SimpleThrowResult {
    const results = getResultsDictionary(unifiedResult);
    return {
        result: results["result"][0].value,
        count: results["count"][0].value,
        dieType: unifiedResult.dieType
    };
}

export function ToRangeThrowResult(unifiedResult: UnifiedDiceTrowResult): RangeThrowResult {
    const results = getResultsDictionary(unifiedResult);
    return {
        results: results["results"].map(r => r.value),
        count: results["count"][0].value,
        dieType: unifiedResult.dieType
    };
}

export function ToMultipleThrowResult(unifiedResult: UnifiedDiceTrowResult): MultipleThrowResult {
    const results = getResultsDictionary(unifiedResult);
    console.log("multiple");
    console.log(unifiedResult);
    console.log(results);
    return {
        results: results["results"].map(r => r.value),
        dieType: unifiedResult.dieType
    };
}