import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import CthulhuDice from './dice/CthulhuDice';
import {Switch, Route, Link } from "react-router-dom";

function App() {
  return (
      <Switch>
          <Route path="/dice/cthulhu">
              <CthulhuDice />
          </Route>
          <Route path="/">
              <Home />
          </Route>
      </Switch>
  );
}

export default App;
