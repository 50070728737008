import { gql } from '@apollo/client';

export const GET_THROWS_QUERY = gql`
query getThrows($top: Int!) {
  throws(top: $top) {
    id
    throwType
    dieType
    results {
      name
      values {
        value
        isHighlighted
      }
    }
  }
}`;

export const ON_DICE_THROWN_SUBSCRIPTION = gql`
subscription diceThrownSubscription {
  onDiceThrown {
    throwType
    dieType
    results {
      name
      values {
        value
        isHighlighted
      }
    }
  }
}`;

export const THROW_CTHULHU_DICE_MUTATION = gql`
mutation throwCthulhuDiceMutation {
  throwCthulhuDice {
    baseThrow
    oneDieBonus
    twoDieBonus
    oneDiePenalty
    twoDiePenalty
    allPartialThrows
  }
}`;

export const THROW_SIMPLE_DICE_MUTATION = gql`
mutation throwSimpleDiceMutation($dieType: DieType!) {
  throw(dieType: $dieType) {
    result
    dieType
    count
  }
}`;

export const THROW_MULTIPLE_DICE_MUTATION = gql`
mutation throwMultipleDiceMutation($requestedThrows: [MultipleThrowPartInput]) {
  throwMultiple(requestedThrows: $requestedThrows) {
    results
    dieType
  }
}`;