import { SimpleThrowResult } from '../types';
import ThrowResultsList from './ThrowResultsList';
import {
    Card,
    CardText,
    Badge
} from 'reactstrap';

function SimpleDiceResult(throwResult: SimpleThrowResult, id: string) {
    return (
        <Card body key={id}>
            <CardText>
                <Badge color="primary">{throwResult.count}{throwResult.dieType}:</Badge>
                {ThrowResultsList([throwResult.result])}
            </CardText>
        </Card>
    );
}

export default SimpleDiceResult;