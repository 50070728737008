import React from 'react';
import {
    Container,
    Row,
    Col,
    Media,
    Button,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Navbar,
    NavbarText
} from 'reactstrap';
import ThrowResults from './ThrowResults';
import { CoCThrowResult, MultipleThrowPartInput, MultipleThrowResult, SimpleThrowResult } from './types';
import CthulhuDiceResult from './results/CthulhuDiceResult';
import SimpleDiceResult from './results/SimpleDiceResult';
import * as Queries from './queries';
import { useMutation } from '@apollo/client';
import MultipleDiceResult from './results/MultipleDiceResult';

const cocResultPlaceholder: CoCThrowResult = {
    baseThrow: 0,
    oneDieBonus: 0,
    twoDieBonus: 0,
    oneDiePenalty: 0,
    twoDiePenalty: 0,
    allPartialThrows: [0, 0, 0, 0, 0, 0]
};

const simpleResultPlaceholder: SimpleThrowResult = {
    result: 0,
    count: 1,
    dieType: 'D0'
};

const multipleResultPlaceholder: MultipleThrowResult = {
    results: [0],
    dieType: 'D0'
};

function CthulhuThrow() {
    const [throwDice, { data }] = useMutation<{throwCthulhuDice: CoCThrowResult}>(Queries.THROW_CTHULHU_DICE_MUTATION);
    return (
        <Media>
            <Media left><Button onClick={() => throwDice()}>D100</Button></Media>
            <Media body>{CthulhuDiceResult(data && data.throwCthulhuDice || cocResultPlaceholder, '0')}</Media>
        </Media>
    );
}

function SimpleThrow() {
    const [throwDice, { data }] = useMutation<{throw: SimpleThrowResult}>(Queries.THROW_SIMPLE_DICE_MUTATION);
    const throwDieOfType = (type: string) => throwDice({variables: {dieType: type}});
    return (
        <Media>
            <Media left>
                <Container>
                    <Row>
                        <Col>
                            <ButtonGroup className="w-100">
                                <Button size="sm" onClick={() => throwDieOfType('D4')}>D4</Button>
                                <Button size="sm" onClick={() => throwDieOfType('D6')}>D6</Button>
                                <Button size="sm" onClick={() => throwDieOfType('D8')}>D8</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonGroup className="w-100">
                                <Button size="sm" onClick={() => throwDieOfType('D10')}>D10</Button>
                                <Button size="sm" onClick={() => throwDieOfType('D12')}>D12</Button>
                                <Button size="sm" onClick={() => throwDieOfType('D20')}>D20</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Container>
            </Media>
            <Media body>{SimpleDiceResult(data && data.throw || simpleResultPlaceholder, '0')}</Media>
        </Media>
    );
}

function MultipleThrow() {
    const [throwDice, { data }] = useMutation<{throwMultiple: MultipleThrowResult}>(Queries.THROW_MULTIPLE_DICE_MUTATION);
    const throwDieOfType = (reqThrows: MultipleThrowPartInput[]) => throwDice({variables: {requestedThrows: reqThrows}});
    return (
        <Media>
            <Media left>
                <Container>
                    <Row>
                        <Col>
                            <ButtonGroup className="w-100">
                                <Button size="sm" onClick={() => throwDieOfType([{dieType: 'D3'}])}>D3</Button>
                                <Button size="sm" onClick={() => throwDieOfType([{dieType: 'D3'},{dieType: 'D4'}])}>D3+D4</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Container>
            </Media>
            <Media body>{MultipleDiceResult(data && data.throwMultiple || multipleResultPlaceholder, '0')}</Media>
        </Media>
    );
}

function CthulhuDice() {
    return (
      <Container>
          <Row>
              <Col>
                  <Navbar color="light" light>
                      <NavbarText>Cthulhu Dice</NavbarText>
                  </Navbar>
              </Col>
          </Row>
          <Row>
              <Col md="8">
                  <ListGroup flush>
                      <ListGroupItem><CthulhuThrow /></ListGroupItem>
                      <ListGroupItem><SimpleThrow /></ListGroupItem>
                      <ListGroupItem><MultipleThrow /></ListGroupItem>
                  </ListGroup>
              </Col>
              <Col md="4">
                  <ThrowResults />  
              </Col>
          </Row>
      </Container>
    );
}

export default CthulhuDice;